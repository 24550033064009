import React from "react";
import { Helmet } from "react-helmet";

const AppMetaTags = () => {
  return (
    <Helmet> 
      <title>DJPb Conference</title>
      <meta name="title" content="DJPb Conference" />
      <meta name="description" content="Konferensi Virtual dengan fitur Lobi, Acara Utama, Ruang Rapat Virtual, Relasi, Percakapan, Polling dan Pengumuman" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://conference.setditjen-djpb.net/" />
      <meta property="og:title" content="DJPb Conference" />
      <meta property="og:description" content="Konferensi Virtual dengan fitur Lobi, Acara Utama, Ruang Rapat Virtual, Relasi, Percakapan, Polling dan Pengumuman" />
      <meta property="og:image" content="https://conference.setditjen-djpb.net/DefaultEventBanner.svg" />
	  
	    <meta itemprop="name" content="DJPb Conference"/>
	    <meta itemprop="description" content="Konferensi Virtual dengan fitur Lobi, Acara Utama, Ruang Rapat Virtual, Relasi, Percakapan, Polling dan Pengumuman"/>
	    <meta itemprop="image" content="https://conference.setditjen-djpb.net/DefaultEventBanner.svg"/>
	   <link rel="icon" type="image/png" href="https://conference.setditjen-djpb.net/icons/favicon.ico"/>

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://conference.setditjen-djpb.net/" />
      <meta property="twitter:title" content="DJPb Conference" />
      <meta property="twitter:description" content="Konferensi Virtual dengan fitur Lobi, Acara Utama, Ruang Rapat Virtual, Relasi, Percakapan, Polling dan Pengumuman" />
      <meta property="twitter:image" content="https://conference.setditjen-djpb.net/DefaultEventBanner.svg" />
    </Helmet>
  )
}

export default AppMetaTags;
